<template>
  <b-modal
    id="modal-question"
    v-model="show"
    :title="title"
    :size="showAnswers ? 'xl' : 'lg'"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row>
          <b-col :class="{ 'border-right': showAnswers }">
            <b-row>
              <b-col>
                <basic-input
                  label="Mã câu hỏi"
                  required
                  placeholder="Nhập mã câu hỏi"
                  name="code"
                  :state="validateState(`code`)"
                  :invalid-feedback="errors.first(`code`)"
                  v-validate="'required|alpha_num|max:200'"
                  data-vv-as="Câu trả lời"
                  class="score-input"
                  :value.sync="form.code"
                  disabled
                ></basic-input>
              </b-col>
              <b-col>
                <basic-input
                  label="Số thứ tự câu hỏi"
                  required
                  placeholder="Nhập số thứ tự câu hỏi"
                  name="order"
                  :state="validateState(`order`)"
                  :invalid-feedback="errors.first(`order`)"
                  :value.sync="form.order"
                  data-vv-as="Số thứ tự câu hỏi"
                  v-validate="'required|numeric'"
                  disabled
                ></basic-input>
              </b-col>
            </b-row>
            <basic-input
              label="Tiêu đề câu hỏi"
              required
              placeholder="Nhập tiêu đề câu hỏi"
              name="name"
              :value.sync="form.name"
              :state="validateState(`name`)"
              :invalid-feedback="errors.first(`name`)"
              data-vv-as="Tiêu đề câu hỏi"
              v-validate="'required'"
              disabled
            ></basic-input>
            <basic-select
              required
              label="Loại câu hỏi"
              placeholder="--- Chọn loại câu hỏi ---"
              name="type"
              :solid="false"
              :allowEmpty="false"
              value-label="text"
              track-by="value"
              :options="questionTypes"
              :value.sync="form.type"
              :state="validateState(`type`)"
              :invalid-feedback="errors.first(`type`)"
              data-vv-as="Tiêu đề câu hỏi"
              v-validate="'required'"
              disabled
            />
            <b-form-group v-if="showScoreCheckbox" label="Tính điểm">
              <b-form-checkbox
                id="checkbox-score"
                v-model="form.isScore"
                name="checkbox-score"
                disabled
              >
                Tính điểm trên từng đáp án
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Điều kiện hiển thị (nếu có)">
              <b-form-checkbox
                id="checkbox-related-question"
                v-model="form.isRelatedQuestions"
                name="checkbox-related-question"
                disabled
              >
                Liên quan đến câu hỏi khác
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-related-patient"
                v-model="form.isRelatedPatients"
                name="checkbox-related-patient"
                disabled
              >
                Liên quan đến thông tin của khách hàng
              </b-form-checkbox>
            </b-form-group>
            <b-row v-if="form.isRelatedQuestions || form.isRelatedPatients">
              <b-col>
                <basic-select
                  required
                  label="Nếu"
                  placeholder="--- Câu hỏi ---"
                  name="question"
                  :solid="false"
                  :allowEmpty="true"
                  value-label="text"
                  track-by="value"
                  disabled
                />
              </b-col>
              <b-col>
                <basic-input
                  label="Điều kiện"
                  placeholder="Là"
                  name="condition"
                  disabled
                ></basic-input>
              </b-col>
              <b-col>
                <basic-select
                  required
                  label="Câu trả lời"
                  placeholder="--- Đáp án ---"
                  name="answer"
                  :solid="false"
                  :allowEmpty="true"
                  value-label="text"
                  track-by="value"
                  disabled
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="showAnswers">
            <div class="font-weight-bolder mb-2">
              Định nghĩa danh sách câu trả lời
            </div>
            <v-data-table
              v-if="!isRating"
              :headers="headers"
              :items="answers"
              id="sortable"
              hide-default-footer
              ref="sortableTable"
              :items-per-page="-1"
            >
              <template v-slot:item="{ item }">
                <tr class="cursor-pointer sortableRow position-static">
                  <td>{{ item.name }}</td>
                  <td class="text-center py-4">
                    {{ item.score }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <b-row class="my-2">
                <b-col cols="4">
                  <basic-select
                    name="from"
                    :solid="false"
                    :allowEmpty="false"
                    value-label="text"
                    track-by="value"
                    class="mb-0 mx-4"
                    :options="ratingFrom"
                    :value.sync="form.rateFrom"
                    disabled
                  />
                </b-col>
                <b-col>
                  <b-form-input
                    v-model="form.titleBegin"
                    placeholder="Tiêu đề (không bắt buộc)"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col cols="4">
                  <basic-select
                    name="to"
                    :solid="false"
                    :allowEmpty="false"
                    value-label="text"
                    track-by="value"
                    class="mb-0 mx-4"
                    :options="ratingTo"
                    :value.sync="form.rateTo"
                    disabled
                  />
                </b-col>
                <b-col>
                  <b-form-input
                    v-model="form.titleEnd"
                    placeholder="Tiêu đề (không bắt buộc)"
                    disabled
                  />
                </b-col>
              </b-row>
            </template>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button class="btn ml-3" type="button" @click="cancelModal">
            Huỷ
          </b-button>
          <b-button v-if="!isDetail" class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { sortBy } from 'lodash';
import { MODAL_TYPE } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalQuestion',
  props: {
    type: {
      type: String,
      default: MODAL_TYPE.CREATE,
    },
    id: {
      type: String,
      default: null,
    },
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        isScore: false,
        isRelatedQuestions: false,
        isRelatedPatients: false,
        type: {
          text: 'Multiple choices',
          value: 2,
        },
        rateTo: {
          text: '5',
          value: 5,
        },
        rateFrom: {
          text: '1',
          value: 1,
        },
        code: null,
        name: null,
        order: null,
        titleBegin: null,
        titleEnd: null,
      },
      answers: [],
      questionTypes: [
        {
          text: 'Single choice',
          value: 1,
        },
        {
          text: 'Multiple choices',
          value: 2,
        },
        {
          text: 'Text',
          value: 3,
        },
        {
          text: 'Rating',
          value: 4,
        },
      ],
      ratingFrom: [
        {
          text: '0',
          value: '0',
        },
        {
          text: '1',
          value: '1',
        },
      ],
      ratingTo: [
        {
          text: '2',
          value: '2',
        },
        {
          text: '3',
          value: '3',
        },
        {
          text: '4',
          value: '4',
        },
        {
          text: '5',
          value: '5',
        },
        {
          text: '6',
          value: '6',
        },
        {
          text: '7',
          value: '7',
        },
        {
          text: '8',
          value: '8',
        },
        {
          text: '9',
          value: '9',
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState(['questions']),
    title() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Tạo câu hỏi';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa câu hỏi';

        default:
          return 'Xem chi tiết câu hỏi';
      }
    },
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
    headers() {
      if (!this.isDetail) {
        return [
          {
            text: '',
            value: 'actionMove',
            sortable: false,
            width: '1%',
          },
          {
            text: 'Câu trả lời',
            value: 'answer',
            sortable: false,
          },
          {
            text: 'Điểm',
            value: 'score',
            sortable: false,
            align: 'center',
          },
          {
            text: '',
            value: 'action',
            sortable: false,
            width: '1%',
          },
        ];
      }
      return [
        {
          text: 'Câu trả lời',
          value: 'answer',
          sortable: false,
        },
        {
          text: 'Điểm',
          value: 'score',
          sortable: false,
          align: 'center',
        },
      ];
    },
    showAnswers() {
      if (this.form.type && this.form.type.value !== 3) return true;
      return false;
    },
    isRating() {
      if (this.form.type && this.form.type.value === 4) return true;
      return false;
    },
    showScoreCheckbox() {
      if (this.form.type && [1, 2].includes(this.form.type.value)) return true;
      return false;
    },
    isRequireAnswer() {
      return this.answers.length > 1 ? false : true;
    },
    isRequireScore() {
      if (this.form.isScore && !this.answers.length) return true;
      return false;
    },
    isEdit() {
      return this.type === MODAL_TYPE.EDIT;
    },
    isDetail() {
      return this.type === MODAL_TYPE.DETAIL;
    },
    isCreate() {
      return this.type === MODAL_TYPE.CREATE;
    },
  },
  created() {
    this.setupData();
  },
  methods: {
    setupData() {
      this.form.code = this.question.code;
      this.form.name = this.question.name;
      this.form.order = this.question.order;
      this.form.isScore = this.question.isScore;
      this.form.isRelatedPatients = this.question.isRelatedPatients;
      this.form.isRelatedQuestions = this.question.isRelatedQuestions;
      this.form.type = this.questionTypes.find(
        (el) => el.value === this.question.type,
      );
      this.answers = sortBy(
        this.question.answers.map((el) => {
          return {
            name: el.content,
            score: el.point,
          };
        }),
      );
      if (this.isRating) {
        const answer = this.question.answers[0];
        this.form.titleBegin = answer.titleBegin;
        this.form.titleEnd = answer.titleEnd;
        this.form.rateFrom = this.ratingFrom.find(
          (el) => Number(el.value) === answer.rangeBegin,
        );
        this.form.rateTo = this.ratingTo.find(
          (el) => Number(el.value) === answer.rangeEnd,
        );
      }
    },
    resetModal() {
      this.$store.commit(`context/setModal`, false);
      this.$emit('reset');
    },
    cancelModal() {
      this.$store.commit(`context/setModal`, false);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.btn-save {
  color: #008479 !important;
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
}
.btn-delete {
  color: #ff5756 !important;
  background-color: #ffebef !important;
  border-color: #ffebef !important;
}
</style>

<style lang="scss">
.score-input {
  .form-group {
    margin-bottom: 0 !important;
  }
}
</style>
